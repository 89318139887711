export const navToggle = (function(){

	let my = {};

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						el.setAttribute('clicked', true);
					} 

					const dropElements = document.querySelectorAll('.has_drop');
					dropElements.forEach(dropElement => {
						dropElement.classList.remove('clicked');
					});
					el.classList.add('clicked');
				}
			});
		});
	}

	function _mobileToggle() {
		document.getElementById('site_nav_toggle').addEventListener('click', function(e){
			let clickedButton = e.currentTarget;
			let nav = document.getElementById('header_nav');

			clickedButton.toggleAttribute('open');
			nav.toggleAttribute('open');
		});
	}

	my.init = function(){
		_addDoubleClick();
		_mobileToggle();
	};

	return my;
})();